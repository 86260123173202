@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-primary {
    @apply rounded-lg bg-blue-500 py-2 px-4 font-semibold text-white shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
}

.primary-shadow {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
}

.f-col {
    @apply flex flex-col;
}

.f-row {
    @apply flex flex-row;
}

.centered {
    @apply items-center justify-center;
}

.f-col-center {
    @apply flex flex-col items-center;
}

.f-col-center-center {
    @apply flex flex-col items-center justify-center;
}

.f-row-between {
    @apply flex justify-between;
}

.f-row-center {
    @apply flex justify-center;
}

.f-row-center-center {
    @apply flex items-center justify-center;
}

.input-style {
    @apply w-full rounded-lg  border border-gray-300 bg-white p-1.5 text-sm shadow-sm ring-orient-400 hover:ring-2 hover:ring-offset-2  focus:border focus:border-gray-400 focus:ring-orient-500 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:border-gray-600 dark:bg-gray-700;
}

input[type='radio'] {
    @apply h-4 w-4 border-gray-300 text-orient-500 shadow-sm ring-orient-400 focus:border focus:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2;
}

input[type='checkbox'] {
    @apply h-4 w-4 rounded border-gray-300 text-orient-500 shadow-sm ring-orient-400 focus:border focus:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2;
}

.PhoneInputInput {
    @apply input-style;
}

.btn-styl {
    @apply rounded bg-orient-500 p-1 text-white   ring-orient-500 hover:bg-orient-700 focus:ring-2 focus:ring-orient-900 focus:ring-offset-2 disabled:bg-orient-300;
}

select,
.rule-value {
    @apply input-style;
}

.rule-remove {
    @apply rounded bg-orient-500 px-3 py-1 text-white   hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75;
}

.shader {
    background: rgb(255, 255, 255);

    border: 1px solid rgb(219, 214, 225);
    box-shadow: rgb(43 34 51 / 4%) 0px 1px 4px;
}

.responsive-wrapping {
    @apply flex-wrap lg:flex-nowrap;
}

/*.rounded, .rounded-md, .rounded-full{*/
/*    border-radius: 0px !important;*/
/*}*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800&family=Inter:wght@100;200;300;400;500;700&display=swap');
span,
a,
p,
.epRaQS,
label,
button,
input,
select,
small,
textarea,
table,
tr,
td,
th,
thead,
tbody,
tfoot,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
.css-1p3m7a8-multiValue {
    font-family: 'Cairo', sans-serif;
    text-align: right;
}

select {
    background-position: left 0.5rem center;
}

.css-1p3m7a8-multiValue {
    font-weight: bold;
}

/*span,a {*/
/*    font-family: 'Inter', sans-serif;*/
/*}*/

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(192, 192, 192, 0.07);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.17);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.rdrDateRangePickerWrapper {
    direction: ltr;
}

.react-grid-layout {
    direction: ltr;
}

.rti--input.go3450369076 {
    padding: 0px 0px 0px 0px !important;
}

.rti--container.go2761622576 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.inactiveLink {
    pointer-events: none;
    cursor: default;
    color: #999;
}

.rdt_Table {
    font-family: inherit !important;
}

.lnndaO,
.dsueRn {
    white-space: pre-line !important;
}

.css-13cymwt-control {
    @apply input-style;
}

.rdt_TableCol div:first-child {
    white-space: normal !important;
    overflow: visible !important;
}

.border-moving {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #858585 50%, transparent 50%),
    linear-gradient(90deg, #b9b9b9 50%, transparent 50%),
    linear-gradient(0deg, #696969 50%, transparent 50%),
    linear-gradient(0deg, #757575 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 3px 3px, 3px 3px, 3px 3px, 3px 3px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    border-radius: 5px;
    padding: 10px;
    animation: dash 100s linear infinite;
}

@keyframes dash {
    to {
        background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
}

.json-view {
    direction: ltr;
}
